import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import Table, {
  dateFormatter,
  buttonsStyle,
} from "../../../components/tables/table";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { getUsers, deleteUser } from "../../../../api/user";
import { Button, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { alertError, alertSuccess } from "../../../../utils/logger";
import { useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

function getData(admins) {
  let data = [];
  for (let i = 0; i < admins.length; ++i) {
    if (admins[i].role === "admin") {
      const elem = {};
      elem.name = admins[i].fullName;
      elem.email = admins[i].email;
      elem.createdAt = admins[i].createdAt;
      elem.id = admins[i]._id;
      data = data.concat(elem);
    }
  }
  return data;
}

export default function AdminsPage() {
  const [data, setData] = useState([]);
  const [usersAllInfo, setUsersAllInfo] = useState({});
  const [adminId, setAdminId] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const user = useSelector((store) => store.authentication?.user, shallowEqual);
  const history = useHistory();

  function buttonFormatter(cell) {
    return user._id === cell || user.superAdmin ? (
      <>
        <Tooltip title="Editar">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => history.push("/edit-admin/" + cell)}
          >
            <EditIcon />
          </Button>
        </Tooltip>
        <Tooltip title="Eliminar">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => {
              setAdminId(cell);
              setOpenConfirmDialog(true);
            }}
          >
            <DeleteIcon />
          </Button>
        </Tooltip>
      </>
    ) : null;
  }

  const columns = [
    { dataField: "name", text: "Nombre", sort: true },
    { dataField: "email", text: "Correo electrónico", sort: true },
    {
      dataField: "createdAt",
      text: "Fecha de creación",
      formatter: dateFormatter,
      sort: true,
    },
    { dataField: "id", text: "", formatter: buttonFormatter },
  ];

  useEffect(() => {
    getUsers()
      .then((res) => {
        if (res.status === 200) {
          setData(getData(res.data));
          setUsersAllInfo(res.data);
          setRefresh(false);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "No se han podido obtener los administradores.",
        });
      });
  }, [refresh]);

  const downloadOptics = () => {
    const jsonData = JSON.stringify(
      usersAllInfo.filter((user) => user.role === "optic")
    );
    const csvData = convertToCSV(jsonData);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "optics.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  function convertToCSV(jsonData) {
    const data = typeof jsonData !== "object" ? JSON.parse(jsonData) : jsonData;

    // Function to flatten the data
    const flattenObject = (obj, parent, res = {}) => {
      for (let key in obj) {
        let propName = parent ? parent + "_" + key : key;
        if (
          typeof obj[key] == "object" &&
          obj[key] !== null &&
          !(obj[key] instanceof Date)
        ) {
          flattenObject(obj[key], propName, res);
        } else {
          res[propName] = obj[key];
        }
      }
      return res;
    };

    // Flatten each data object
    let flatData = data.map((item) => flattenObject(item));

    // Extract column headers
    let csv = "";
    let headers = Object.keys(flatData[0]).join(",");
    csv += headers + "\r\n";

    // Convert each data object to a CSV row
    for (let i = 0; i < flatData.length; i++) {
      let row = "";
      for (let key in flatData[i]) {
        if (row !== "") row += ",";
        let value = flatData[i][key];
        if (typeof value === "string") {
          value = value.replace(/"/g, '""'); // Escape double quotes
          if (value.includes(",") || value.includes("\n")) {
            value = '"' + value + '"';
          }
        }
        row += value;
      }
      csv += row + "\r\n";
    }
    return csv;
  }

  return (
    <>
      <Card>
        <CardHeader title="Lista de administradores">
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary mx-2"
              onClick={() => history.push("/edit-admin")}
            >
              Añadir nuevo
            </button>
            <button
              type="button"
              className="btn btn-primary mx-2"
              onClick={() => downloadOptics(true)}
            >
              Descargar Ópticas
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Table data={data} columns={columns} />
          <ConfirmDialog
            title={"¿Está seguro de que quiere eliminar este administrador?"}
            open={openConfirmDialog}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              deleteUser(adminId)
                .then((res) => {
                  if (res.status === 204 || res.status === 200) {
                    alertSuccess({
                      title: "¡Eliminado!",
                      customMessage: "Administrador eliminado con éxito.",
                    });
                    setRefresh(true);
                  }
                })
                .catch((error) => {
                  alertError({
                    error: error,
                    customMessage: "No se ha podido eliminar el administrador.",
                  });
                });
            }}
          />
        </CardBody>
      </Card>
    </>
  );
}
