import { authClient, API, baseClient } from "../index";
import { uploadSingleFile } from "../files/index";

// Get all users
export const getUsers = () => {
  return authClient().get(`${API}/user`);
};

// Get user by id
export const getUserById = (id) => {
  return authClient().get(`${API}/user/${id}`);
};

// Delete user
export const deleteUser = (id) => {
  return authClient().delete(`${API}/user/${id}`);
};

// Create user
export const postUser = async (user, image, logo) => {
  if (image) {
    const response = await uploadSingleFile(image, "optics");
    user.imageURL = response.data.fileURL;
  }
  if (logo) {
    const response = await uploadSingleFile(logo, "optics");
    user.logoMail = response.data.fileURL;
  }
  return authClient().post(`${API}/user`, user);
};

// Update user
export const updateUser = async (id, user, image, logo) => {
  if (image) {
    const response = await uploadSingleFile(image, "optics");
    user.imageURL = response.data.fileURL;
  }
  if (logo) {
    const response = await uploadSingleFile(logo, "optics");
    user.logoMail = response.data.fileURL;
  }
  return authClient().put(`${API}/user/${id}`, user);
};

// Count all admins
export const countAdmins = () => {
  return authClient().get(`${API}/user/count/admin`);
};

// Count all optics
export const countOptics = () => {
  return authClient().get(`${API}/user/count/optic`);
};

// Send registration mail
export const sendRegistrationMail = (email) => {
  return authClient().post(`${API}/user/send-registration-mail`, { email });
};

//Send helper mail
export const sendHelperMail = (data) => {
  return authClient().post(`${API}/user/send-help-email`, { data });
};

export const checkPermissionGoogleCalendar = async (calendarId) => {
  const res = await baseClient().get(`${API}/user/google-token`);
  if (res.status !== 200) return res;
  const new_token = res.data.token;
  return await baseClient().get(
    `${API}/user/google-events?email=${calendarId}&token=${new_token}`
  );
};

export const checkPermissionOutlookCalendar = async (calendarId) => {
  const res = await baseClient().get(`${API}/user/microsoft-token`);
  if (res.status !== 200) return res;
  const new_token = res.data.token;
  return await baseClient().get(
    `${API}/user/microsoft-events?email=${calendarId}&token=${new_token}`
  );
};
