import Swal from 'sweetalert2'

// Error messages from api returned as {message: 'error message text'}
export const logError = ({ error, customMessage = 'No custom message from backoffice' }) => {
  error?.response?.status
  	? console.log (
  		'ERROR\n' +
        `Status: ${error.response.status}.\n` +
        `Status error: ${error.response.statusText}.\n` +
        `API Message: ${error.response.data?.message}\n` +
        `Backoffice Message: ${customMessage}`
  	)
  	: console.log (
  		'ERROR\n' +
        `Backoffice message: ${customMessage}\n` +
        `${error}`
  	)
}

export const logFormData = (formData) => {
	console.group('FormData')
	for (let pair of formData.entries())
		console.log('key:', pair[0]+ ', value: '+ pair[1])

	console.groupEnd('FormData')
}

export const alertError = ({ error, customMessage }) => {
	const message =
    error?.response?.data?.message ||
    error?.response?.statusText ||
    error ||
    customMessage ||
    'Error has no message'

	const params = {
		icon: 'error',
		title: (customMessage?.length < 30 && customMessage) || 'Ups...',
		text: message
	}

	Swal.fire(params)
}

export const alertSuccess = ({ customMessage } = {}) => {
	const message =
    customMessage ||
    '¡Operación exitosa!'

	const params = {
		icon: 'success',
		title: 'Ok!',
		text: message
	}

	Swal.fire(params)
}

export const alertCalendarPermission = ({ onConfirm = () => {} }) => {
	const params = {
		icon: 'warning',
		title: '¡Aún no se ha dado ningún permiso para integrar un calendario externo!',
		text: '',
		confirmButtonText: 'Dar permiso',
		denyButtonText: 'Cerrar',
		showDenyButton: true
	}

	Swal.fire(params).then((result) => {
		if (result.isConfirmed) {
			onConfirm()
		}
	})
}