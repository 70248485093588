import React, { useState } from 'react'
import { AppBar, Tabs, Tab } from '@material-ui/core'

function DaysTabBlock({ daysTabContent, days, daysLabels }) {
	const [tab, setTab] = useState(0)

	const handleChangeTabs = (_, newValue) => {
		setTab(newValue)
	}

	if (!days || !days.length)
		return (
			<div> Cargando... </div>
		)

	return (
		<>
			<AppBar position="static" color="default" key="appbar">
				<Tabs
					value={tab}
					onChange={handleChangeTabs}
					variant="scrollable"
					scrollButtons="auto"
					key="tabs"
				>
					{days?.map((day, index) => (
						<Tab
							key={day}
							label={daysLabels[day]} />
					))}
				</Tabs>
			</AppBar>
			{days?.map((day, index) =>
				tab === index && (
					<div key={`tabContainer-${index}`}>
						{daysTabContent(day)}
					</div>
				)
			)}
		</>
	)
}

export default DaysTabBlock
