import React from "react";
import { useFormik } from "formik";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import { forgotPassword } from "../../../../api/auth/index"
import { alertSuccess } from "../../../../utils/logger"

const initialValues = {
  email: "",
};

function ForgotPassword(props) {
  const history = useHistory()
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Formato de email inválido")
      .min(3, "Mínimo 3 caracteres")
      .max(50, "Máximo 50 caracteres")
      .required("Campo requerido"),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(async () => {
        try {
          await forgotPassword({ email: values.email })
          alertSuccess({ title: '¡Correo electrónico enviado!', customMessage: 'Por favor, revise su correo electrónico. Le hemos enviado un enlace para recuperar la contraseña.' })
          history.push('/auth')
          setSubmitting(false)
        } catch (error) {
          setStatus(
            `${values.email} no encontrado`
          );
        }
        setSubmitting(false);
      }, 1000);
    },
  });

  return (
    <>
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Contraseña olvidada?</h3>
            <div className="text-muted font-weight-bold">
              Ingrese su correo electrónico para restablecer la contraseña
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <input
                type="email"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                name="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}
              >
                Aceptar
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  style={{ color: '#fff' }}
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancelar
                </button>
              </Link>
            </div>
          </form>
        </div>
    </>
  );
}

export default injectIntl(ForgotPassword);
