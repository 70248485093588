import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import Table, {
  dateFormatter,
  buttonsStyle,
} from "../../../components/tables/table";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import {
  getUsers,
  deleteUser,
  sendRegistrationMail,
} from "../../../../api/user";
import { Button, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MailIcon from "@material-ui/icons/Mail";
import { alertError, alertSuccess } from "../../../../utils/logger";
import { useHistory } from "react-router-dom";

function getData(optics) {
  let data = [];
  for (let i = 0; i < optics.length; ++i) {
    if (optics[i].role === "optic") {
      const elem = {};
      elem.name = optics[i].fullName;
      elem.email = optics[i].email;
      elem.registered = optics[i].registerCompleted;
      elem.createdAt = optics[i].createdAt;
      elem.id = optics[i]._id;
      data = data.concat(elem);
    }
  }
  return data;
}

export default function OpticsPage() {
  const [data, setData] = useState([]);
  const [opticId, setOpticId] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const history = useHistory();

  function sendMail(email) {
    sendRegistrationMail(email)
      .then((res) => {
        if (res.status === 200) {
          alertSuccess({
            title: "¡Hecho!",
            customMessage:
              "El correo electrónico para completar el registro ha sido reenviado.",
          });
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "No se ha podido enviar el correo electrónico.",
        });
      });
  }

  function buttonFormatter(cell) {
    const elem = data.find((item) => item.id === cell);
    return (
      <>
        <Tooltip title="Editar">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => history.push("/edit-optic/" + cell)}
          >
            <EditIcon />
          </Button>
        </Tooltip>
        <Tooltip title="Eliminar">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => {
              setOpticId(cell);
              setOpenConfirmDialog(true);
            }}
          >
            <DeleteIcon />
          </Button>
        </Tooltip>
        {!elem?.registered && (
          <Tooltip title="Reenviar correo electrónico de registro">
            <Button
              style={buttonsStyle}
              size="small"
              onClick={() => sendMail(elem?.email)}
            >
              <MailIcon />
            </Button>
          </Tooltip>
        )}
      </>
    );
  }

  const columns = [
    { dataField: "name", text: "Nombre", sort: true },
    { dataField: "email", text: "Correo electrónico", sort: true },
    {
      dataField: "registered",
      text: "Estado del registro",
      sort: true,
      formatter: (cell) =>
        !cell ? (
          <span className="badge badge-danger">No registrado</span>
        ) : (
          <span className="badge badge-primary">Completado</span>
        ),
    },
    {
      dataField: "createdAt",
      text: "Fecha de creación",
      formatter: dateFormatter,
      sort: true,
    },
    { dataField: "id", text: "", formatter: buttonFormatter },
  ];

  useEffect(() => {
    let isMounted = true;
    getUsers()
      .then((res) => {
        if (isMounted) {
          if (res.status === 200) {
            setData(getData(res.data));
            setRefresh(false);
          }
        }
      })
      .catch((error) => {
        if (isMounted) {
          alertError({
            error: error,
            customMessage: "No se han podido obtener las ópticas.",
          });
        }
      });

    return () => {
      isMounted = false;
    };
  }, [refresh]);

  return (
    <>
      <Card>
        <CardHeader title="Lista de ópticas">
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => history.push("/edit-optic")}
            >
              Añadir nueva
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Table data={data} columns={columns} />
          <ConfirmDialog
            title={"¿Está seguro de que quiere eliminar esta óptica?"}
            open={openConfirmDialog}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              deleteUser(opticId)
                .then((res) => {
                  if (res.status === 204 || res.status === 200) {
                    alertSuccess({
                      title: "¡Eliminada!",
                      customMessage: "Óptica eliminada con éxito.",
                    });
                    setRefresh(true);
                  }
                })
                .catch((error) => {
                  alertError({
                    error: error,
                    customMessage: "No se ha podido eliminar la óptica.",
                  });
                });
            }}
          />
        </CardBody>
      </Card>
    </>
  );
}
