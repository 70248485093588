import React, { useEffect, useState } from 'react';
import { alertError } from '../../utils/logger';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import StoreIcon from '@material-ui/icons/Store';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import { countAdmins, countOptics } from '../../api/user';

export function DashboardPage() {
	const [data, setData] = useState([]);
	const user = useSelector((store) => store.authentication?.user, shallowEqual)
    const history = useHistory()

	useEffect(() => {
		async function fetchData() {
			try {
				const administrators = await countAdmins()
				const optics = await countOptics()

				setData({
					numAdmins: administrators.data,
					numOptics: optics.data
				})
			} catch (err) {
				alertError({ error: err, customMessage: 'No se han podido obtener las estadísticas.'})
			}
		}
		fetchData()
	}, []);

	return (
		<>
			{user?.role.includes('admin') && <div className='row justify-content-center'>
				<div className='col-12 col-md-4 text-center my-2' role='button' onClick={() => history.push('/admins')}>
					<div className='card'>
						<div className='card-body'>
							<h5 className='card-title'><AssignmentIndIcon/>  Administradores</h5>
							<div className='contentDash'>{data.numAdmins}</div>
						</div>
					</div>
				</div>
				<div className='col-12 col-md-4 text-center my-2' role='button' onClick={() => history.push('/optics')}>
					<div className='card'>
						<div className='card-body'>
							<h5 className='card-title'><StoreIcon/>  Ópticas</h5>
							<div className='contentDash'>{data.numOptics}</div>
						</div>
					</div>
				</div>
			</div>}
		</>
	);
}
