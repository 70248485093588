import React from 'react'
import { Redirect, Switch } from 'react-router-dom'
import { ContentRoute } from '../_metronic/layout'
import { DashboardPage } from './pages/DashboardPage'
import AdminsPage from './modules/ContentManager/admins/AdminsPage'
import EditAdminsPage from './modules/ContentManager/admins/EditAdminsPage'
import OpticsPage from './modules/ContentManager/optics/OpticsPage'
import EditOpticsPage from './modules/ContentManager/optics/EditOpticsPage'
import { shallowEqual, useSelector } from 'react-redux'

export default function BasePage() {
	const user = useSelector((store) => store.authentication?.user, shallowEqual)
	const isAdmin = user?.role === 'admin'

	return (
		<Switch>
			{/* Redirect from root URL to /dashboard. */ }
			<Redirect exact from="/" to="/dashboard" />
			<ContentRoute path="/dashboard" component={DashboardPage} />

			{/* Administrators */}
			<ContentRoute from="/admins" component={isAdmin ? AdminsPage : DashboardPage} />
			<ContentRoute from="/edit-admin/:id?" component={isAdmin ? EditAdminsPage : DashboardPage} />
			{/* Optics */}
			<ContentRoute from="/optics" component={isAdmin ? OpticsPage : DashboardPage} />
			<ContentRoute from="/edit-optic/:id?" component={EditOpticsPage} />

			<Redirect to="/error" />
		</Switch>
	)
}
