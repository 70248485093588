import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
  Button,
  TextField,
  MuiThemeProvider,
  createMuiTheme,
  FormControl,
  FormHelperText,
  Tooltip,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import {
  deleteUser,
  getUserById,
  postUser,
  updateUser,
  checkPermissionGoogleCalendar,
  checkPermissionOutlookCalendar,
  sendHelperMail,
} from "../../../../api/user";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import CreatableSelect from "react-select/creatable";
import DaysTabBlock from "../../../components/DaysTabBlock";
import { shallowEqual, useSelector } from "react-redux";
import { ArrowUpward, ArrowDownward, Delete } from "@material-ui/icons";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { buttonsStyle } from "../../../components/tables/table";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import { SERVER_URL } from "../../../../api/index";
import { Visibility } from "@material-ui/icons";

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#F64E60",
    },
  },
});

const checkEmptyAppoinmentTypes = (types) => {
  if (!types || !types.length) return true;
  for (const t of types) {
    if (!t || !Object.keys(t)?.length) return true;
    for (const key of Object.keys(t)) {
      if (!t[key].length) return true;
    }
  }
  return false;
};

const weekdaysLabels = {
  monday: "Lunes",
  tuesday: "Martes",
  wednesday: "Miércoles",
  thursday: "Jueves",
  friday: "Viernes",
  saturday: "Sábado",
  sunday: "Domingo",
};

const weekdays = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];
let initialOfficeHours = {};
weekdays.forEach(
  (day) =>
    (initialOfficeHours = {
      ...initialOfficeHours,
      [day]: [
        {
          start: null,
          end: null,
        },
      ],
    })
);

function getEmptyOptic() {
  return {
    fullName: "",
    email: "",
    role: "optic",
    password: null,
    address: "",
    cp: "",
    city: "",
    province: "",
    longitude: "",
    latitude: "",
    phone: "",
    schedule: "",
    informeds: [],
    officeHours: initialOfficeHours,
    appointments: [{ type: "Otra consulta", time: "30" }],
    registerCompleted: false,
    calendarPermission: {
      calendar: "none",
      permission: false,
    },
    imageURL: "",
    logoMail: "",
    cat: "",
    nWhatsapp: "",
    clientCode: "",
  };
}

export default function EditOpticsPage() {
  const [optic, setOptic] = useState(getEmptyOptic());
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [newPassword, setNewPassword] = useState({
    password: null,
    repeatPassword: null,
  });
  const [changePassword, setChangePassword] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [openPreviewLogo, setOpenPreviewLogo] = useState(false);
  const opticId = useParams().id;
  const history = useHistory();
  const user = useSelector((store) => store.authentication?.user, shallowEqual);

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  const handleGoBack = () => {
    if (user?.role === "admin") history.push("/optics");
    else history.push("/dashboard");
  };

  useEffect(() => {
    if (!opticId) {
      disableLoadingData();
      return;
    }
    getUserById(opticId)
      .then((res) => {
        if (res.status === 200) {
          const user = res.data;
          delete user.password;
          setOptic(user);
          console.log("CATEOGRIA OPTICA: ", user.cat);
          disableLoadingData();
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "No se ha podido obtener la óptica.",
        });
        handleGoBack();
      });
  }, [opticId, disableLoadingData, history]);

  async function saveOptic() {
    if (checkEmptyAppoinmentTypes(optic.appointments)) {
      return alertError({
        error: null,
        customMessage:
          "Por favor, complete toda la información necesaria sobre los tipos de consulta.",
      });
    }
    if (!opticId) {
      postUser(optic, selectedImage, selectedLogo)
        .then((res) => {
          if (res.status === 201) {
            alertSuccess({
              title: "¡Guardada!",
              customMessage: "Óptica creada con éxito.",
            });
            handleGoBack();
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "No se ha podido guardar la óptica.",
          });
        });
    } else {
      let saveOptic = optic;
      if (changePassword) {
        if (!newPassword.password || !newPassword.repeatPassword) {
          alertError({
            error: null,
            customMessage: "Por favor, ingrese una contraseña.",
          });
          return;
        }
        if (newPassword.password !== newPassword.repeatPassword) {
          alertError({
            error: null,
            customMessage: "Las contraseñas no coinciden.",
          });
          return;
        }
        saveOptic = { ...saveOptic, password: newPassword.password };
      }
      if (optic.calendarPermission.calendar === "google") {
        try {
          const response = await checkPermissionGoogleCalendar(
            optic.calendarPermission.email
          );
          if (response.status !== 200)
            return alertError({
              error: null,
              customMessage:
                'Por favor, haga clic en el botón "Dar permiso" o seleccione la opción "Ninguno".',
            });
          saveOptic = {
            ...saveOptic,
            calendarPermission: {
              ...saveOptic.calendarPermission,
              permission: true,
            },
          };
        } catch (err) {
          return alertError({
            error: null,
            customMessage:
              'Por favor, haga clic en el botón "Dar permiso" o seleccione la opción "Ninguno".',
          });
        }
      } else if (optic.calendarPermission.calendar === "microsoft") {
        try {
          // const response = await checkPermissionOutlookCalendar(optic.calendarPermission.email)
          // if (response.status !== 200) return alertError({ error: null, customMessage: 'Por favor, haga clic en el botón "Dar permiso" o seleccione la opción "Ninguno".' })
          saveOptic = {
            ...saveOptic,
            calendarPermission: {
              ...saveOptic.calendarPermission,
              permission: true,
            },
          };
        } catch (err) {
          return alertError({
            error: null,
            customMessage:
              'Por favor, haga clic en el botón "Dar permiso" o seleccione la opción "Ninguno".',
          });
        }
      }
      updateUser(opticId, saveOptic, selectedImage, selectedLogo)
        .then((res) => {
          if (res.status === 200) {
            alertSuccess({
              title: "¡Guardada!",
              customMessage: "Los cambios se han guardado con éxito.",
            });
            handleGoBack();
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "No se han podido guardar los cambios.",
          });
        });
    }
  }

  function handleHelperMail() {
    sendHelperMail(optic)
      .then((res) => {
        if (res.status === 200) {
          alertSuccess({
            title: "¡Hecho!",
            customMessage:
              "El correo electrónico para solicitar ayuda se ha enviado con éxito.",
          });
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "No se ha podido enviar el correo electrónico.",
        });
      });
  }

  const handleChange = (element, day, subelement, index) => (event) => {
    if (event.target.value === " ") return;
    if (day) {
      let newText = optic[element];
      const slots = [...newText[day]];
      const aux = { ...slots[index], [subelement]: event.target.value };
      slots.splice(index, 1, aux);
      newText = { ...newText, [day]: slots };
      setOptic({ ...optic, officeHours: newText });
    } else setOptic({ ...optic, [element]: event.target.value });
  };

  const handleChangeInformeds = (event) => {
    const emails = event ? event.map((item) => item.value) : [];
    console.log(emails);
    setOptic({ ...optic, informeds: emails });
  };

  const handleCreateInformeds = (event) => {
    setOptic({ ...optic, informeds: optic.informeds.concat(event) });
  };

  const handleChangeOpticType = (selectedOption) => {
    setOptic({ ...optic, type: selectedOption ? selectedOption.value : "" });
  };

  const handleGooglePermission = () => {
    window.open(
      `https://calendar.google.com/calendar/u/0/r?share=vimax.lens.cp@gmail.com`,
      "_blank"
    );
  };

  const handleMicrosoftPermission = () => {
    window.open(`https://outlook.office365.com/calendar/view/month`, "_blank");
  };

  const renderDaysTabContentOfficeHours = (day) => {
    return (
      <>
        <br />
        {optic?.officeHours &&
          optic.officeHours[day]?.map((item, index) => {
            const slot = `Franja horaria ${index + 1}`;
            return (
              <React.Fragment key={index}>
                <h5>{slot}</h5>
                <div className="row">
                  <div className="col-11">
                    <TextField
                      id={`${day} ${slot} start time`}
                      label={`Hora de inicio`}
                      value={item?.start || ""}
                      onChange={handleChange(
                        "officeHours",
                        day,
                        "start",
                        index
                      )}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="time"
                      margin="normal"
                      variant="outlined"
                      required
                    />
                    <TextField
                      id={`${day} ${slot} end time`}
                      label={`Hora de fin`}
                      value={item?.end || ""}
                      onChange={handleChange("officeHours", day, "end", index)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="time"
                      margin="normal"
                      variant="outlined"
                      required
                    />
                  </div>
                  <div className="col-1 d-flex align-items-center">
                    <Tooltip title="Eliminar">
                      <span>
                        <Button
                          size="small"
                          style={buttonsStyle}
                          disabled={optic.officeHours[day]?.length === 1}
                          onClick={() => {
                            let _hours = optic.officeHours;
                            _hours[day].splice(index, 1);
                            setOptic({ ...optic, officeHours: _hours });
                          }}
                        >
                          <Delete />
                        </Button>
                      </span>
                    </Tooltip>
                  </div>
                </div>
                <br />
                <br />
              </React.Fragment>
            );
          })}
        <br />
        <Button
          onClick={() => {
            let _hours = optic.officeHours;
            if (_hours) {
              if (_hours[day]) _hours[day].push({ start: null, end: null });
              else _hours[day] = [{ start: null, end: null }];
            } else _hours = initialOfficeHours;
            setOptic({ ...optic, officeHours: _hours });
          }}
          variant="outlined"
          color="primary"
        >
          Añadir nueva franja horaria
        </Button>
      </>
    );
  };

  const handleChangeAppointmentTypes = (index, element) => (event) => {
    if (event.target.value === " ") return;
    let newText = optic.appointments || [{ label: "", time: "" }];
    newText[index][element] = event.target.value;
    setOptic({ ...optic, appointments: newText });
  };

  const handleMoveAppointmentType = (index, newIndex) => {
    const aux = optic.appointments[index];
    let types = [...optic.appointments];
    types.splice(index, 1, types[newIndex]);
    types.splice(newIndex, 1, aux);
    setOptic({ ...optic, appointments: types });
  };

  const handleDeleteAppointmentType = (index) => {
    let types = [...optic.appointments];
    types.splice(index, 1);
    setOptic({ ...optic, appointments: types });
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(
      `https://vimax.desarrollopajarraco.es/buscador-opticas/?opticaId=${optic._id}`
    );
    alertSuccess({
      title: "Done!",
      customMessage: "Optic url successfully copied to clipboard.",
    });
  };

  const options = [
    { value: "VIMAX", label: "Vimax" },
    { value: "VIMAX SE", label: "Vimax SE" },
  ];

  if (isLoadingData) return <ContentSkeleton />;
  else
    return (
      <>
        <Card>
          <CardHeader title="Editar óptica"></CardHeader>
          <CardBody>
            <TextField
              id={`fullName`}
              label="Nombre completo"
              value={optic.fullName}
              onChange={handleChange("fullName")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <TextField
              id={`email`}
              label="Correo electrónico"
              value={optic.email}
              onChange={handleChange("email")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <TextField
              id={`phone`}
              label="Teléfono"
              value={optic.phone}
              onChange={handleChange("phone")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <span>
              El horario introducido aquí, será el mostrado en el mapa de la
              óptica
            </span>
            <TextField
              id={`schedule`}
              label="Horario"
              value={optic?.schedule ? optic.schedule : ""}
              onChange={handleChange("schedule")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            {/* <TextField
              id={`nWhatsapp`}
              label="Whatsapp"
              value={optic.nWhatsapp}
              onChange={handleChange("nWhatsapp")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            /> */}
            <TextField
              id={`clientCode`}
              label="Código de cliente"
              value={optic.clientCode}
              onChange={handleChange("clientCode")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <TextField
              id={`address`}
              label="Dirección"
              value={optic.address}
              onChange={handleChange("address")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <TextField
              id={`cp`}
              label="Código postal"
              value={optic.cp}
              onChange={handleChange("cp")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <TextField
              id={`city`}
              label="Ciudad"
              value={optic.city}
              onChange={handleChange("city")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <TextField
              id={`province`}
              label="Província"
              value={optic.province}
              onChange={handleChange("province")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <TextField
              id={`latitude`}
              label="Latitud"
              value={optic.latitude}
              onChange={handleChange("latitude")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <TextField
              id={`longitude`}
              label="Longitud"
              value={optic.longitude}
              onChange={handleChange("longitude")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <CreatableSelect
              className="mb-5 mt-5"
              styles={{ cursor: "pointer" }}
              isClearable
              value={options.find((option) => option.value === optic.cat)}
              onChange={handleChangeOpticType}
              options={options}
              placeholder="Selecciona el tipo de óptica"
            />
            <div className="d-flex align-items-center direction-row">
              <section className="">
                Copia el link de acceso a la óptica
              </section>
              <Tooltip title="Copy code">
                <Button
                  style={buttonsStyle}
                  size="small"
                  onClick={() => copyToClipboard()}
                >
                  <FileCopyIcon />
                </Button>
              </Tooltip>
            </div>
            <br />
            <br />
            <label htmlFor={"upload-image"}>
              <input
                style={{ display: "none" }}
                id={"upload-image"}
                name={"upload-image"}
                type="file"
                accept={"image/*"}
                onChange={(e) => {
                  setSelectedImage(e.target.files[0]);
                }}
              />
              <Button
                style={{ marginRight: "15px" }}
                color="secondary"
                component="span"
                variant="outlined"
              >
                {selectedImage || (optic.imageURL && optic.imageURL !== "")
                  ? "Change image"
                  : "Upload image"}
              </Button>
            </label>
            {(selectedImage || (optic.imageURL && optic.imageURL !== "")) && (
              <>
                <Tooltip title={"Preview image"}>
                  <span>
                    <Button
                      size="small"
                      onClick={() => setOpenPreviewDialog(true)}
                      style={{ ...buttonsStyle, marginRight: "15px" }}
                    >
                      <Visibility />
                    </Button>
                  </span>
                </Tooltip>
                <PreviewDialog
                  title={"Preview image"}
                  open={openPreviewDialog}
                  setOpen={setOpenPreviewDialog}
                  src={
                    selectedImage
                      ? URL.createObjectURL(selectedImage)
                      : `${SERVER_URL}/${optic.imageURL}`
                  }
                />
                <span>
                  {selectedImage
                    ? selectedImage?.name
                    : optic.imageURL && optic.imageURL !== ""
                    ? optic.imageURL?.split(/-(.*)/s)[1]
                    : ""}
                </span>
              </>
            )}
            <br />
            <label htmlFor={"upload-logo"}>
              <input
                style={{ display: "none" }}
                id={"upload-logo"}
                name={"upload-logo"}
                type="file"
                accept={"image/*"}
                onChange={(e) => {
                  setSelectedLogo(e.target.files[0]);
                }}
              />
              <Button
                style={{ marginRight: "15px" }}
                color="secondary"
                component="span"
                variant="outlined"
              >
                {selectedLogo || (optic.logoMail && optic.logoMail !== "")
                  ? "Change logo"
                  : "Upload logo"}
              </Button>
            </label>
            {(selectedLogo || (optic.logoMail && optic.logoMail !== "")) && (
              <>
                <Tooltip title={"Preview logo"}>
                  <span>
                    <Button
                      size="small"
                      onClick={() => setOpenPreviewLogo(true)}
                      style={{ ...buttonsStyle, marginRight: "15px" }}
                    >
                      <Visibility />
                    </Button>
                  </span>
                </Tooltip>
                <PreviewDialog
                  title={"Preview logo"}
                  open={openPreviewLogo}
                  setOpen={setOpenPreviewLogo}
                  src={
                    selectedLogo
                      ? URL.createObjectURL(selectedLogo)
                      : `${SERVER_URL}/${optic.logoMail}`
                  }
                />
                <span>
                  {selectedLogo
                    ? selectedLogo?.name
                    : optic.logoMail && optic.logoMail !== ""
                    ? optic.logoMail?.split(/-(.*)/s)[1]
                    : ""}
                </span>
              </>
            )}
          </CardBody>
          <CardHeader title="Tipos de consulta"></CardHeader>
          <CardBody>
            {optic.appointments?.map((item, index) => (
              <>
                <div
                  className="row mt-5 align-items-center"
                  style={{ margin: "auto", justifyContent: "space-between" }}
                  key={index}
                >
                  <TextField
                    id={`label`}
                    label="Nombre"
                    className="col-7 col-lg-8"
                    value={item.label}
                    onChange={handleChangeAppointmentTypes(index, "label")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="normal"
                    variant="outlined"
                    required
                  />
                  <TextField
                    id={`time`}
                    label="Duración (minutos)"
                    className="col-2"
                    value={item.time}
                    onChange={handleChangeAppointmentTypes(index, "time")}
                    InputLabelProps={{
                      shrink: true,
                      min: 0,
                      step: 1,
                    }}
                    type="number"
                    margin="normal"
                    variant="outlined"
                    required
                  />
                  <Tooltip title="Eliminar">
                    <span>
                      <Button
                        size="small"
                        style={buttonsStyle}
                        disabled={optic.appointments.length === 1}
                        onClick={() => handleDeleteAppointmentType(index)}
                      >
                        <Delete />
                      </Button>
                    </span>
                  </Tooltip>
                  <Tooltip title="Mover hacia arriba">
                    <span>
                      <Button
                        size="small"
                        style={buttonsStyle}
                        disabled={index === 0}
                        onClick={() =>
                          handleMoveAppointmentType(index, index - 1)
                        }
                      >
                        <ArrowUpward />
                      </Button>
                    </span>
                  </Tooltip>
                  <Tooltip title="Mover hacia abajo">
                    <span>
                      <Button
                        size="small"
                        disabled={index >= optic.appointments.length - 1}
                        style={{ ...buttonsStyle, marginRight: "1em" }}
                        onClick={() =>
                          handleMoveAppointmentType(index, index + 1)
                        }
                      >
                        <ArrowDownward />
                      </Button>
                    </span>
                  </Tooltip>
                </div>
                <br />
              </>
            ))}
            <br />
            <br />
            <Button
              variant="outlined"
              color="primary"
              onClick={() =>
                setOptic({
                  ...optic,
                  appointments: optic.appointments.concat({
                    label: "",
                    time: "30",
                  }),
                })
              }
            >
              Añadir nuevo tipo
            </Button>
          </CardBody>
          <CardHeader title="Correos electrónicos para notificaciones"></CardHeader>
          <CardBody>
            <FormControl style={{ width: "100%" }} className="ml-2">
              <FormHelperText>Correos electrónicos</FormHelperText>
              <CreatableSelect
                className="mb-3"
                isClearable
                isMulti
                value={optic.informeds?.map((item) => ({
                  label: item,
                  value: item,
                }))}
                onChange={handleChangeInformeds}
                onCreateOption={handleCreateInformeds}
                options={[]}
                placeholder="Escriba un correo electrónico..."
              />
            </FormControl>
          </CardBody>
          <CardHeader title="Horario"></CardHeader>
          <CardBody>
            <DaysTabBlock
              daysTabContent={renderDaysTabContentOfficeHours}
              days={weekdays}
              daysLabels={weekdaysLabels}
            />
          </CardBody>
          {opticId && (
            <>
              <CardHeader title="Integración calendario externo"></CardHeader>
              <CardBody>
                {(optic.calendarPermission?.calendar === "none" ||
                  !optic.calendarPermission?.permission) && (
                  <>
                    <h6 style={{ color: "red" }}>
                      <ErrorOutlineIcon /> Aún no se ha dado ningún permiso para
                      integrar un calendario externo.
                    </h6>
                    <br />
                  </>
                )}
                <FormLabel style={{ color: "black" }}>
                  Seleccione un calendario externo para la sincronización
                </FormLabel>
                <br />
                <br />
                <FormControl>
                  <RadioGroup
                    aria-label="calendar"
                    name="calendarPermission"
                    value={optic.calendarPermission?.calendar}
                    onChange={(event) => {
                      if (event.target.value === " ") return;
                      let newElement = optic.calendarPermission;
                      newElement.permission = false;
                      newElement.calendar = event.target.value;
                      setOptic({ ...optic, calendarPermission: newElement });
                    }}
                  >
                    <FormControlLabel
                      value="none"
                      control={<Radio />}
                      label="Ninguno"
                    />
                    <FormControlLabel
                      value="google"
                      control={<Radio />}
                      label="Calendario de Google"
                    />
                    <FormControlLabel
                      value="microsoft"
                      control={<Radio />}
                      label="Calendario de Microsoft Outlook"
                    />
                  </RadioGroup>
                </FormControl>
                {optic.calendarPermission?.calendar === "google" ? (
                  <>
                    <br />
                    <br />
                    {!optic.calendarPermission?.permission ? (
                      <>
                        <FormLabel>
                          Introduzca el correo electrónico de su cuenta de
                          calendario.
                        </FormLabel>
                        <br />
                        <TextField
                          id={`email-calendar`}
                          label="Correo electrónico"
                          value={optic.calendarPermission.email}
                          onChange={(event) => {
                            if (event.target.value === " ") return;
                            let newElement = optic.calendarPermission;
                            newElement.email = event.target.value;
                            setOptic({
                              ...optic,
                              calendarPermission: newElement,
                            });
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          margin="normal"
                          variant="outlined"
                          required
                        />
                        <br />
                        <br />
                        <FormLabel>
                          Ahora haga clic en el botón "Dar permiso" y siga los
                          pasos para compartirnos el acceso de gestión de
                          eventos a su calendario para la gestión de citas.
                        </FormLabel>
                        <br />
                        <br />
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleGooglePermission()}
                        >
                          Dar permiso
                        </Button>
                      </>
                    ) : (
                      <FormLabel>Permiso concedido.</FormLabel>
                    )}
                    <br />
                  </>
                ) : (
                  <></>
                )}
                {optic.calendarPermission?.calendar === "microsoft" ? (
                  <>
                    <br />
                    <br />
                    {!optic.calendarPermission?.permission ? (
                      <>
                        <p>
                          Para poder obtener las citas en tu calendario de
                          outlook debe seguir los siguientes pasos:{" "}
                        </p>
                        <ol>
                          <li>
                            Pulsar en los 3 puntos que hay al lado del
                            calendario que desee compartir
                          </li>
                          <li>Entrar en Uso compartido y permisos</li>
                          <li>
                            Introducir el email de la entidad:
                            Vimax@VimaxLens.onmicrosoft.com
                          </li>
                          <li>
                            Especificar que el usuario podrá editar el
                            calendario y pulsar en compartir
                          </li>
                          <li>
                            Recuerda que el proceso puede tardar ya que el
                            administrador tiene que aceptar su solicitud de
                            compartición de calendario
                          </li>
                          <br />
                          <p style={{ fontWeight: "bold" }}>
                            Asegurese de que al pulsar el botón dar permiso se
                            encuentra en la página de outlook con la cuenta que
                            contiene el calendario que desea compartir
                          </p>
                        </ol>
                        <FormLabel>
                          Introduzca el correo electrónico de su cuenta de
                          calendario.
                        </FormLabel>
                        <br />
                        <TextField
                          id={`email-calendar`}
                          label="Correo electrónico"
                          value={optic.calendarPermission.email}
                          onChange={(event) => {
                            if (event.target.value === " ") return;
                            let newElement = optic.calendarPermission;
                            newElement.email = event.target.value;
                            setOptic({
                              ...optic,
                              calendarPermission: newElement,
                            });
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          margin="normal"
                          variant="outlined"
                          required
                        />
                        <br />
                        <br />
                        <FormLabel>
                          Ahora haga clic en el botón "Dar permiso" y siga los
                          pasos para compartirnos el acceso a su calendario para
                          la gestión de citas.
                        </FormLabel>
                        <br />
                        <br />
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleMicrosoftPermission()}
                        >
                          Dar permiso
                        </Button>
                      </>
                    ) : (
                      <FormLabel>Permiso concedido.</FormLabel>
                    )}
                    <br />
                  </>
                ) : (
                  <></>
                )}
              </CardBody>
            </>
          )}
          {opticId && user._id === opticId && (
            <>
              <CardHeader title="Contraseña de acceso"></CardHeader>
              <CardBody>
                {changePassword ? (
                  <>
                    <TextField
                      id={`password`}
                      label="Contraseña"
                      value={newPassword.password}
                      onChange={(event) => {
                        if (event.target.value !== " ")
                          setNewPassword({
                            ...newPassword,
                            password: event.target.value,
                          });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="password"
                      margin="normal"
                      variant="outlined"
                      required
                    />
                    <TextField
                      id={`repeatPassword`}
                      label="Repetir contraseña"
                      value={newPassword.repeatPassword}
                      onChange={(event) => {
                        if (event.target.value !== " ")
                          setNewPassword({
                            ...newPassword,
                            repeatPassword: event.target.value,
                          });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="password"
                      margin="normal"
                      variant="outlined"
                      required
                    />
                    <Button
                      onClick={() => {
                        setChangePassword(false);
                        setNewPassword({
                          password: null,
                          repeatPassword: null,
                        });
                      }}
                      variant="outlined"
                      style={{ marginRight: "20px" }}
                    >
                      Cancelar cambio de contraseña
                    </Button>
                  </>
                ) : (
                  <Button
                    onClick={() => setChangePassword(true)}
                    variant="outlined"
                    color="primary"
                    style={{ marginRight: "20px" }}
                  >
                    Cambiar contraseña
                  </Button>
                )}
              </CardBody>
            </>
          )}
        </Card>
        <Button
          onClick={() => handleGoBack()}
          variant="outlined"
          style={{ marginRight: "20px" }}
        >
          Atrás
        </Button>
        <Button
          onClick={() => saveOptic()}
          variant="outlined"
          color="primary"
          style={{ marginRight: "20px" }}
        >
          Guardar
        </Button>
        {opticId && user?.role === "admin" && (
          <>
            <MuiThemeProvider theme={theme}>
              <Button
                onClick={() => setOpenConfirmDialog(true)}
                variant="outlined"
                color="secondary"
              >
                Eliminar
              </Button>
            </MuiThemeProvider>

            <ConfirmDialog
              title={"¿Está seguro de que quiere eliminar esta óptica?"}
              open={openConfirmDialog}
              setOpen={setOpenConfirmDialog}
              onConfirm={() => {
                deleteUser(opticId)
                  .then((res) => {
                    if (res.status === 204 || res.status === 200) {
                      alertSuccess({
                        title: "¡Eliminada!",
                        customMessage: "Óptica eliminada con éxito.",
                      });
                    }
                  })
                  .catch((error) => {
                    alertError({
                      error: error,
                      customMessage: "No se ha podido eliminar la óptica.",
                    });
                  });
              }}
            />
          </>
        )}
      </>
    );
}
