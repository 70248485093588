import React from 'react'
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import '../../../customStyles/bootstrap_table.css'
import CheckBox from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlank  from '@material-ui/icons/CheckBoxOutlineBlank'

const { SearchBar, ClearSearchButton } = Search

export const buttonsStyle = { maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', marginLeft: '1em' }

export function booleanFormatter(cell) {
  return cell
    ? <CheckBox style={{ color: '#D91E48' }}/>
    : <CheckBoxOutlineBlank style={{ color: '#D91E48' }}/>
}

export function longTextFormatter(cell) {
  return <td className='truncate'>
    <div dangerouslySetInnerHTML = {{ __html: cell }}></div>
  </td>
}

const buildDate = (date) => {
  if (Object.prototype.toString.call(date) !== '[object Date]')
    return
  const displayDate = `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}  ${date.getHours()}:${String(date.getMinutes()).padStart(2, "0")}`
  return displayDate
}

export function dateFormatter(cell) {
  let date = new Date(cell)
  date = buildDate(date)

  return date
}

const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total" style={{ paddingLeft: '5px' }}>
    Mostrando { from }-{ to } de { size } resultados
  </span>
);

const pagination = paginationFactory({
  page: 1,
  sizePerPage: 10,
  lastPageText: '>>',
  firstPageText: '<<',
  nextPageText: '>',
  prePageText: '<',
  showTotal: true,
  paginationTotalRenderer: customTotal,
  alwaysShowAllBtns: false,
  onPageChange: function (page, sizePerPage) {},
  onSizePerPageChange: function (page, sizePerPage) {}
})

export default function Table({ data, columns, ...tableProps }) {

  return (
    <ToolkitProvider
      bootstrap4
      keyField='id'
      data={data}
      columns={columns}
      search
    >
      {props => (
        <div>
          <SearchBar placeholder="Buscar" {...props.searchProps } />
          <ClearSearchButton text="Limpiar" className='ml-3' {...props.searchProps} />
          <br />
          <br />
          <BootstrapTable
            wrapperClasses="table-responsive"
            classes="table table-head-custom table-vertical-center overflow-hidden"
            bordered={false}
            // remote
            pagination={ pagination }
            striped
            {...tableProps}
            {...props.baseProps}
          />
        </div>
      )}
    </ToolkitProvider>
  )
}